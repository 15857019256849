<template>
   <main>
		<HeaderTab 
            :title="componentTitle" 
            :back_action="onBack"
        />
        <div id="content">
            <shutter-panel />
        </div>
    </main>
</template>


<script type="text/javascript">
    import Shutter from '@/mixins/Shutter.js'
    import ShutterActs from '@/mixins/shutters-manager/Acts.js'

	export default {
        name: "ActManagement",
        mixins: [Shutter, ShutterActs],
		data () {
			return {
                /* Main view params */
                componentTitle: '',

                /* Context params */
                horse_ids_route: '',
                actes_ids_route: '',
                usage: 'add',
                lot: false,
                type_preselect:''
			}
        },
        created() {
            if(this.$route.params.acte_id) {
                this.actes_ids_route = this.$route.params.acte_id.toString()
            }
            if(this.$route.params.horse_id) {
                this.horse_ids_route = this.$route.params.horse_id.toString()
            }
            if(this.$route.params.state) {
                this.usage = this.$route.params.state
            }
            if(this.$route.params.lot) {
                this.lot = this.$route.params.lot
            }
            if(this.$route.params.type_preselect) {
                this.type_preselect = this.$route.params.type_preselect
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            init_component() {
                switch (this.usage) {
                    case 'modification':
                        this.setupActEdit(this.actes_ids_route, this.lot)
                        this.componentTitle = this.getTrad('acte.modifier_acte')
                        break
                    case 'validation':
                        this.setupActValidation(this.actes_ids_route)
                        this.componentTitle = this.getTrad('acte.valider_acte')
                        break
                    default:
                        this.setupActAdd(this.horse_ids_route, '', this.type_preselect)
                        this.componentTitle = this.getTrad('acte.ajouter_acte')
                        break
                }
            },

            onShuttersSubmit() {
                this.redirectPostSave()
            },

            redirectPostSave() {
                const from = this.$route.params.from
				const horse_id = this.$route.params.horse_id

                switch (from) {
                    case 'HorseFicheActes' :
                        this.$router.push({ name: from, params: horse_id })
                        break
                    case 'HorseFichePlanning' :
                        this.$router.push({ name: from, params: horse_id })
                        break
                    default :
                        this.$router.push({ name: from })
                }
            }
        },
        components: {
            HeaderTab: () => import('@/components/HeaderTab')
		},
    }
</script>
